

export let  _defaultFormState = {
    //form data
    tokenType: 0,
    defaultNetowrk: '0x3ac',
    tokenNetwork: '0x3ac',
    tokenName: '',
    tokenSymbol: '',
    tokenDecimals: 18,
    initialSupply: 21000000,
    totalSupply: 21000000,
    supplyType: '10k',
    accessType: 'None',
    transferType: 'None',
    userAddress: '',


    templateOptions: {
        //token settings
        verifiedSource: true,
        fixedSupply: true,
        mintable: false,
        capped: false,
        burnable: false,
        pausable: false,
        permit: false,
        votes: false,
        flashMinting: false,
        snapshots: false,

        //access control
        access: false,
        ownable: false,
        roles: false,

        //upgradeable
        upgradeable: false,
        upgradeTransparent: false,
        upgradeUUPS: false,
    },

    securityContact: '',
    license: 'MIT',


    //form validation require field checks
    preCompileTitleError:'',
    preCompileMessage: '',
    isTokenName: false,
    isTokenSymbol: false,
    isTokenDecimals: false,
    isInitialSupply: false,
    isTotalSupply: false,
    termsAndService: false,
    connectionWarning: false,

    //compiled contract
    compiledContract: {},
    deployReceipt: {}
}


