import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

export default function TokenFormDetails(props) {

    let d = {...props.formState};
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleTokenNetworkChange = (event) => {
        d.preCompileTitleError= '';
            d.preCompileMessage=  '';
        d.tokenNetwork = event.target.value;
        d.defaultNetowrk = event.target.value;
        props.stateSetter(d);
    };

    const handleTokenNameChange = (event) => {
        d.tokenName = event.target.value;
        d.tokenName? d.isTokenName = false:d.isTokenName = true
        props.stateSetter(d);
    };
    const handleTokenSymbolChange = (event) => {
        d.tokenSymbol = event.target.value;
        d.tokenSymbol? d.isTokenSymbol = false:d.isTokenSymbol = true
        props.stateSetter(d);
    };
    const handleTokenDecimalsChange = (event) => {
        d.tokenDecimals = event.target.value;
        d.tokenDecimals? d.isTokenDecimals = false:d.isTokenDecimals = true
        props.stateSetter(d);
    };
    const handleInitialSupplyChange = (event) => {
        d.initialSupply = event.target.value;
        d.initialSupply? d.isInitialSupply = false:d.isInitialSupply = true
        props.stateSetter(d);
    };
    const handleTotalSupplyChange = (event) => {

        d.totalSupply = event.target.value;
        d.totalSupply? d.isTotalSupply = false:d.isTotalSupply = true
        props.stateSetter(d);
    };
    const handleTermsAndServiceChange = (event) => {
        d.termsAndService = event.target.checked;
        props.stateSetter(d);

    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };


    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Token Details
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} >
                    <FormControl fullWidth>
                        <InputLabel id="network-type-label">Pulse Network</InputLabel>
                        <Select
                            labelId="network-type-label"
                            id="networkType"
                            value={props.formState.tokenNetwork}
                            label="Pulse Network"
                            onChange={handleTokenNetworkChange}
                        >
                            <MenuItem value={'0x3ac'}>Test Network</MenuItem>
                            <MenuItem value={'0x171'}>Main Network</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id="tokenName"
                        name="tokenName"
                        label="Token name"
                        fullWidth
                        variant="standard"
                        helperText="Please enter a token name"
                        onChange={handleTokenNameChange}
                        defaultValue={props.formState.tokenName}
                        error={props.formState.isTokenName}
                    />
                </Grid>
                <Grid item xs={12} >
                    <TextField
                        required
                        id="tokenSymbol"
                        name="tokenSymbol"
                        label="Token Symbol"
                        fullWidth
                        variant="standard"
                        helperText="Choose a symbol for your token (usually 3-5 chars)."
                        onChange={handleTokenSymbolChange}
                        defaultValue={props.formState.tokenSymbol}
                        error={props.formState.isTokenSymbol}


                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        type="number"
                        required
                        id="tokenDecimals"
                        name="tokenDecimals"
                        label="Token decimals"
                        fullWidth
                        variant="standard"
                        helperText="Insert the decimal length of your token. If not sure, use 18."
                        onChange={handleTokenDecimalsChange}
                        defaultValue={props.formState.tokenDecimals}
                        error={props.formState.isTokenDecimals}

                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        type="number"
                        required
                        id="initialSupply"
                        name="initialSupply"
                        label="Initial Supply"
                        fullWidth
                        variant="standard"
                        helperText="Insert the initial number of tokens minted. Will be minted to your address."
                        onChange={handleInitialSupplyChange}
                        defaultValue={props.formState.initialSupply}
                        error={props.formState.isInitialSupply}

                    />
                </Grid>{props.formState.templateOptions.capped?
                <Grid item xs={12}>
                    <TextField
                        type="number"
                        required
                        id="totalSupply"
                        name="totalSupply"
                        label="Capped Mintable Supply"
                        fullWidth
                        variant="standard"
                        helperText="Insert max tokens ever available"
                        onChange={handleTotalSupplyChange}
                        defaultValue={props.formState.totalSupply}
                        error={props.formState.isTotalSupply}

                    />
                </Grid>: ''}

                <Grid item xs={12}>
                    <FormControlLabel
                        control={<Checkbox onClick={handleTermsAndServiceChange} checked={props.formState.termsAndService}  />}
                        label={ <span onClick={ () => {handleOpen()} }><u>Agree to Terms and Conditions</u></span>  }
                    />
                </Grid>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Terms and Conditions
                        </Typography>
                        <Typography id="modal-modal-description" sx={{mt: 2}}>
                            Please take note that this is a beta version feature and is provided on an "as is" and "as available" basis.
                            PRC20GEN does not give any warranties and will not be liable for any loss, direct or indirect through continued use of this software.
                        </Typography>
                    </Box>
                </Modal>
            </Grid>
        </React.Fragment>
    );
}
