import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";

export default function WalletButton({ provider, loadWeb3Modal, logoutOfWeb3Modal, networkState, setNetworkState, addressState, setAddressState }) {
    const [account, setAccount] = useState("");
    const [rendered, setRendered] = useState("");
    const [network, setNetwork] = useState("");


    useEffect(() => {
        let address = addressState;
        let network = networkState;

        async function fetchNetwork() {
            try {
                if (!provider) {
                    return;
                }


                const networks = await provider.detectNetwork();
                let chainId = '';
                if(networks.chainId === 940){
                    chainId = '0x3ac'
                }
                if( networks.chainId === 369){
                    chainId = '0x171'
                }
                if( networks.chainId === 941){
                    chainId = '0x3ad'
                }
                setNetworkState (chainId);
                console.log(networks.chainId);
                window.ethereum.on('chainChanged', (chainHexID) => {
                    console.log(chainHexID);
                    setNetworkState (chainHexID);
                    if (chainHexID === '0x3ac' || chainHexID === '0x171' || chainHexID === '0x3ad') {
                        setNetwork("")
                        async function getAccount () {
                            const accounts = await provider.listAccounts();
                            setAddressState(accounts[0]);
                        }
                        getAccount ();
                    }else{
                        setNetwork("Wrong Network");
                        setRendered("");
                    }
                });

                if(chainId === '0x3ac' || chainId === '0x171' || chainId === '0x3ad'){

                    const accounts = await provider.listAccounts();
                    setAddressState(accounts[0]);
                    setNetwork("");
                }else{
                    setNetwork("Wrong Network");
                    setRendered("");
                }
            } catch (err) {
                setNetwork("Network Fail ");

                //setRendered("");
                console.error(err);
            }
        }

        async function fetchAccount() {
            try {
                if (!provider) {
                    return;
                }
                // Load the user's accounts.
                const accounts = await provider.listAccounts();

                setAddressState(accounts[0]);
                setAccount(accounts[0]);
                let currentAccount = null;
                window.ethereum.on('accountsChanged', handleAccountsChanged);

                 // For now, 'eth_accounts' will continue to always return an array
                function handleAccountsChanged(accounts) {
                    setAddressState(accounts[0]);
                    if (accounts.length === 0) {
                        // MetaMask is locked or the user has not connected any accounts

                        setRendered("Connect Wallet");
                    } else if (accounts[0] !== currentAccount) {
                        setAccount(accounts[0]);

                        setNetwork("");
                        setRendered(account.substring(0, 6) + "..." + account.substring(36));

                        // Do any other work!
                    }
                }

                setRendered(account.substring(0, 6) + "..." + account.substring(36));
                //formData.currentConnectedAccount = account;
            } catch (err) {
                setAccount("");
                setRendered("Account Fail");
                console.error(err);
            }
        }
        fetchAccount();
        fetchNetwork();
    }, [account, provider, setAccount, setRendered, networkState, setNetworkState, addressState, setAddressState]);

    function getWalletInitalState (){
    if(rendered === '' && network === ''){
        return 'Connect Wallet'
    }

    }
    return (
        <Button
            variant="outlined"
            sx={{color: "2c1143"}}
            onClick={() => {
                if (!provider) {
                    loadWeb3Modal();
                } else {
                    logoutOfWeb3Modal();
                }
            }}
        >   {getWalletInitalState()}
            {rendered !== "" && rendered}
            {network !== "" && network}
        </Button>
    );
}