import React from "react";
//import { CircularProgress } from '@mui/material';
import Typography from "@mui/material/Typography";
import CountUp from "react-countup"
import PulseBeat from "./PulseBeat"

export default function Compile (props)  {
    return (
        <div>
            <Typography variant="h6" gutterBottom>
                {props.loadingMessage}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
               A wild pack of honey badgers are building and compiling your smart contract. Please be patient.
            </Typography>
            <PulseBeat/>
            <br/>
            <CountUp end={1000} duration={1000} />
        </div>

    );
};