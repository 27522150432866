import CssBaseline from '@mui/material/CssBaseline';
import './Checkout.css';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import useWeb3Modal from "./../hooks/useWeb3Modal";
import React, {useEffect, useState} from "react";
import TokenReview from "./TokenReview";
import TokenFormSettings from "./TokenFormSettings"
import TokenFormDetails from "./TokenFormDetails"
import WalletButton from "./WalletButton"
import Compile from "./Compile"
import Deploy from "./Deploy"
import DeployReceipt from "./DeployReceipt";
import Footer from "./Footer";
import {_defaultFormState} from "./formSettings";
import ConnectionWarning from "./ConnectionWarning";


const Web3 = require('web3');
const {ethereum} = window;
let web3 = new Web3(window.ethereum);

//web3.setProvider('https://rpc.v2.testnet.pulsechain.com') || "ws://rpc.v2.testnet.pulsechain.com"


//Pulsechain Testnet 1 ID 940 hex 0x3AC
//Pulsechain Mainet ID 369 hex 0x171


const theme = createTheme({
    palette: {
        background: {
            paper: "white",
            default: "#2c1143"
        },
        primary: {
            main: "#401962",
            light: "#702ca4",
            dark: "#170a26",
            contrastText: "white"
        }
    },
});



/*
let getTestRPCCall = async (state) => {
    let initalSupplyNum = 21000000000000000000000000;
    console.log(initalSupplyNum)
    const settings = {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(testRpcData)
    };
       try {
            const fetchResponse = await fetch(`https://rpc.v2.testnet.pulsechain.com`, settings);

            //const reader = fetchResponse.body
            //    .pipeThrough(new TextDecoderStream())
            //    .getReader();
            console.log(fetchResponse)
            const data = await fetchResponse.json();
            console.log(data)
            //return data;

        } catch (e) {
            console.log(e)
            return e;
        }

}*/






export default function FormWrapper() {
    const [provider, loadWeb3Modal, logoutOfWeb3Modal] = useWeb3Modal();
    const [deployStatus, setDeployStatus] = useState('');
    const [providerNetwork, setProviderNetwork] = useState('');
    const [providerAddress, setProviderAddress] = useState('');
    let [formData, setFormData] = useState(_defaultFormState);
    const [open, setOpen] = useState(false);




    useEffect(() => {
        // Update the document title using the browser API
        document.title = `PRC20GEN`;
    });

    const [activeStep, setActiveStep] = useState(0);

    const handleTokenSettingsNext = () => {
        setActiveStep(activeStep + 1);
    };
    const handleTokenDetailsNext = () => {
        let d = {...formData};
        if (d.tokenName === '' || d.tokenSymbol === '' || d.tokenDecimals === '' || d.initialSupply === '' || d.totalSupply === '' || !d.termsAndService) {
            if (d.tokenName === '') {
                d.isTokenName = true
            }
            if (d.tokenSymbol === '') {
                d.isTokenSymbol = true
            }
            if (d.tokenDecimals === '') {
                d.isTokenDecimals = true
            }
            if (d.initialSupply === '') {
                d.isInitialSupply = true
            }
            if (d.initialSupply <= 0) {
                d.isInitialSupply = true
            }
            if (d.totalSupply === '') {
                d.isTotalSupply = true
            }
            setFormData(d)
        } else {
            setActiveStep(activeStep + 1);
        }
    };
    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const updateFormState = (state) => {
        setFormData(state);
    };


    const steps = ['Settings', 'Details', 'Review'];


    function getStepContent(step, currentState, setCurrentState) {
        switch (step) {
            case 0:
                return <TokenFormSettings formState={currentState} stateSetter={setCurrentState}/>;
            case 1:
                return <TokenFormDetails formState={currentState} stateSetter={setCurrentState}/>;
            case 2:
                return <TokenReview formState={currentState} stateSetter={setCurrentState} />;
            case 3:
                return <Compile loadingMessage={deployStatus}/>;
            case 4:
                return <Deploy formState={currentState} stateSetter={setCurrentState}/>;
            case 5:
                return <DeployReceipt formState={currentState} stateSetter={setCurrentState}/>;
            default:
                throw new Error('Unknown step');
        }
    }

    let testAPI2 =  () =>  {

        let uReceipt = {
            receipt: 'Test',
            cID: 'send'
        }
        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(uReceipt)
        };
        console.log('sending test')
        try {
            (async () => {
                const fetchResponse = await fetch(`https://prc20gen.com/api/update`, settings);
                const response = await fetchResponse.json().then(function (result) {

                })

            })()

        }catch (e){
            console.log(e)
        }

    }



    let getContract = async (formState, updateFormState ) => {

        let d = {...formState};
        d.userAddress = providerAddress;
        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(d)
        };


   /*     let localID = '';
        console.log(window.ethereum.selectedAddress)
        let checkIDStatus = async function() {
           await ethereum.request({
                method: 'eth_chainId',
            }).then( function (chainId) {
               localID = chainId;
           });
        };
        //check network and wallet connection
        await checkIDStatus()
console.log(localID)*/


        if (!provider) {
            d.preCompileTitleError = 'Network Mismatch Detected'
            d.preCompileMessage = 'Check your wallet or contract network selections. Pulsechain Mainet or Testnet only'

            updateFormState(d)
            setOpen(true)
        }else {

            if (providerNetwork !== d.tokenNetwork) {
                d.preCompileTitleError = 'Network Mismatch Detected'
                d.preCompileMessage = 'Check your wallet or contract network selections. Pulsechain Mainet or Testnet only'
                updateFormState(d)
                setOpen(true)
            }else{
                let formData = {...formState}
                console.log('Compiling')
                console.log(settings.body)
                setActiveStep(3)
                try {
                    console.log('send');
                    const fetchResponse = await fetch(`https://prc20gen.com/api/post`, settings);
                    const response = await fetchResponse.json().then(function(result) {


                        formData.compiledContract = result;

                        setFormData(formData);

                        setActiveStep(4)
                    })

                } catch (err) {
                    d.preCompileTitleError = 'Fetch Error'
                    d.preCompileMessage = err

                    updateFormState(d)
                    setOpen(true)
                    console.error(err);
                }
            }
        }

    }

    let getDeploy = async function(formState, setFormState) {
        let data = {...formState};


        let contractObj = new web3.eth.Contract(data.compiledContract.abi);
        console.log('Deploying... ')


        contractObj.deploy({
            data: data.compiledContract.bytecode
        }).send({
            from: window.ethereum.selectedAddress,
        }, function (error, transactionHash) {
            console.log(error);
            //console.log(transactionHash)
            if(transactionHash) {
                data.deployReceipt['transactionHash'] = transactionHash;
                setFormState(data)
                setActiveStep(5)
            }

        }).on('error', function (error) {
                console.log(error)
            })
            .on('transactionHash', function (transactionHash) {
                console.log(transactionHash)
            })
            .on('receipt', function (receipt) {
                let data2 = {...formState};
                console.log(receipt.contractAddress);
                console.log(receipt)// contains the new contract address

                data2.deployReceipt['contractAddress'] = receipt.contractAddress;
                data2.deployReceipt['receipt'] = receipt;

                let uReceipt = {
                    receipt: receipt,
                    cID: data2.compiledContract.cID,
                }

               const settings = {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(uReceipt)
                };
                 try {
            (async () => {
                const fetchResponse = await fetch(`https://prc20gen.com/api/update`, settings);
                await fetchResponse.json().then(function (result) {
                   // console.log(result);
                })
            })()

        }catch (e){
            console.log(e)
        }
                setFormState(data2)

            })
            .on('confirmation', function (confirmationNumber, receipt) {
                let data3 = {...formState};
                data3.deployReceipt['confirmationNumber'] = confirmationNumber;
                console.log(confirmationNumber)
                setFormState(data3)
                //console.log(receipt)
            })
            .then(function (newContractInstance) {
                console.log(newContractInstance);

                                let _tokenSymbol = '';
                                newContractInstance.methods.symbol().call({from: providerAddress}, function (error, result) {
                                    _tokenSymbol = result;
                                    newContractInstance.methods.decimals().call({from: providerAddress}, function (error, result) {


                                        let setTokenWallet = async (formData) => {
                                            const wasAdded = await provider.jsonRpcFetchFunc('wallet_watchAsset',
                                                {
                                                    type: 'ERC20', // Initially only supports ERC20, but eventually more!
                                                    options: {
                                                        address: newContractInstance._address, // The address that the token is at.
                                                        symbol: _tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                                                        decimals: result, // The number of decimals in the token
                                                        image: '', // A string url of the token logo
                                                    }

                                            });

                                         /*   const wasAdded = await provider.jsonRpcFetchFunc({
                                                method: 'wallet_watchAsset',
                                                params: {
                                                    type: 'ERC20', // Initially only supports ERC20, but eventually more!
                                                    options: {
                                                        address: newContractInstance._address, // The address that the token is at.
                                                        symbol: _tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                                                        decimals: result, // The number of decimals in the token
                                                        image: '', // A string url of the token logo
                                                    },
                                                },
                                            });*/

                                            if (wasAdded) {
                                                console.log('Thanks for your interest!');
                                            } else {
                                                console.log('Token not added!');
                                            }
                                        }
                                        setTokenWallet();


                                    });
                                });

            });


    }

    function getDeployReceipt(formState, setFormState) {
        setActiveStep(0)
        setFormState(_defaultFormState)
    }


    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <AppBar
                position="absolute"
                color="default"
                elevation={0}
                sx={{
                    width: '100%',
                    color: "#2c1143",
                    bgcolor: "#fff",
                    position: 'relative',
                    borderBottom: (t) => `1px solid ${t.palette.divider}`,
                }}
            >
                <Toolbar>

                    <Grid
                        container
                    >
                        <Grid item xs={6}>
                            <Typography variant="h6" color="#2c1143" noWrap>
                                PRC20 Generator
                            </Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <div>

                                <WalletButton provider={provider} loadWeb3Modal={loadWeb3Modal}
                                              logoutOfWeb3Modal={logoutOfWeb3Modal} networkState={providerNetwork} setNetworkState={setProviderNetwork}  addressState={providerAddress} setAddressState={setProviderAddress}/>

                            </div>
                        </Grid>
                    </Grid>
                </Toolbar>

            </AppBar>
            <Container component="main" maxWidth="sm" sx={{mb: 4}}>
                <Paper variant="outlined" sx={{my: {xs: 3, md: 6}, p: {xs: 2, md: 3}, bgcolor: 'white'}}>
                    <Typography component="h1" variant="h4" align="center">
                        PulseChain Token Creator
                    </Typography>

                    <Stepper activeStep={activeStep} sx={{pt: 3, pb: 5}}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <React.Fragment >

                        {getStepContent(activeStep, formData, setFormData)}


                        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                            {activeStep !== 0 && activeStep !== 3 && activeStep !== 4 && activeStep !== 5 && (
                                <Button onClick={handleBack} sx={{mt: 3, ml: 1}}>
                                    Back
                                </Button>
                            )}
                            {activeStep === 0 && (
                                <Button
                                    variant="contained"
                                    onClick={handleTokenSettingsNext}
                                    sx={{mt: 3, ml: 1}}
                                >
                                    Next
                                </Button>
                            )}
                         {/*   {activeStep === 0 && (
                                <Button
                                    variant="contained"
                                    onClick={() => {testAPI2()}}
                                    sx={{mt: 3, ml: 1}}
                                >
                                    TEST API
                                </Button>
                            )}*/}
                            {activeStep === 1 && (
                                <Button
                                    variant="contained"
                                    onClick={handleTokenDetailsNext}
                                    sx={{mt: 3, ml: 1}}
                                >
                                    Next
                                </Button>
                            )}
                            {activeStep === 2 && (
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        getContract(formData, updateFormState,)
                                    }}
                                    sx={{mt: 3, ml: 1}}
                                >
                                    Compile
                                </Button>
                            )}
                            {activeStep === 4 && (
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        getDeploy(formData, updateFormState)
                                    }}
                                    sx={{mt: 3, ml: 1}}
                                >
                                    Deploy
                                </Button>
                            )}
                            {activeStep === 5 && (
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        getDeployReceipt(formData, updateFormState)
                                    }}
                                    sx={{mt: 3, ml: 1}}
                                >
                                    Make Another
                                </Button>
                            )}
                        </Box>
                    </React.Fragment>


                </Paper>
                <ConnectionWarning openState={open} message={formData.preCompileMessage} title={formData.preCompileTitleError} setOpenState={setOpen} />
                <Footer/>
            </Container>
        </ThemeProvider>
    );
}
