import * as React from "react";
import Typography from "@mui/material/Typography";


export default function Footer() {
    return (
        <div>
            <Typography sx={{color:'white', fontSize: '11px' }}>
                Feedback: inbox@prc20gen.com
            </Typography>
            <Typography sx={{color:'white', fontSize: '11px'}}>
                Donations: 0xa81395387E8EFd23b0a0a976d32D914c28805476
            </Typography>

        </div>

    );

}

