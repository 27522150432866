import React from "react";


export default function PulseBeat ()  {
    return (
        <div>
            <svg viewBox="-100 -16 200 32">
                <polyline points="-99,0 -11.65,0 -7.95,5.6 -3.85,-12.7 2.65,13.4 7.15,-5.3 11.65,0 99,0"
                          className="pulse-beat" style={{stroke: "#251733", strokeWidth: "1px"}}></polyline>
            </svg>
        </div>

    );
};