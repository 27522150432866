import * as React from "react";
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Grid from "@mui/material/Grid";
import {useState} from "react";
import Popover from '@mui/material/Popover';


export function Deploy(props) {

    const [abi, setAbi] = useState('');
    const [source, setSource] = useState('');
    const [byte, setByte] = useState('');


    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        navigator.clipboard.writeText(abi);
        setAnchorEl(event.currentTarget);
        setTimeout(() => {
            handleClose()
        }, 1000);
    };
    const handleClickByte = (event) => {
        navigator.clipboard.writeText(byte);
        setAnchorEl(event.currentTarget);
        setTimeout(() => {
            handleClose()
        }, 1000);
    };
    const handleClickSource = (event) => {
        navigator.clipboard.writeText(source);
        setAnchorEl(event.currentTarget);
        setTimeout(() => {
            handleClose()
        }, 1000);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const openSource = Boolean(anchorEl);
    const openByte = Boolean(anchorEl);
    const id = open ? 'abi-popover' : undefined;
    const idSource = open ? 'source-popover' : undefined;
    const idByte = open ? 'byte-popover' : undefined;



    React.useEffect(() => {
        let abiString = '';
        props.formState.compiledContract.abi.map(function (methodName, index) {
            if(methodName.name){
                return abiString  += methodName.name + ' (' + methodName.inputs.map(function (inputName) {return '[' + inputName.internalType + ',' + inputName.name + ']'}) + ') \n ' +
                    '    - State Mutability: ' + methodName.stateMutability + '\n' +
                    '     - Type: ' + methodName.type + '\n \n';
            }
            return [];
        })

        setAbi(abiString);

        setSource(props.formState.compiledContract.source);
        setByte(props.formState.compiledContract.bytecode);

    }, [props.formState.compiledContract.abi, props.formState.compiledContract.source, props.formState.compiledContract.bytecode])


    return <React.Fragment>

        <Typography variant="h6" gutterBottom>
            Click Deploy Below
        </Typography>

        <Grid item xs={12} sx={{textAlign: "left"}}>

            <Grid container direction="row">
                <Grid item xs={6} md={5} sx={{textAlign: "left"}}>
                    <b>Token Name:</b>
                </Grid>
                <Grid item xs={6} md={7} sx={{textAlign: "left"}}>
                    {props.formState.tokenName}
                </Grid>
            </Grid>
            <Grid container direction="row">
                <Grid item xs={6} md={5} sx={{textAlign: "left"}}>
                    <b>Token Symbol:</b>
                </Grid>
                <Grid item xs={6} md={7} sx={{textAlign: "left"}}>
                    {props.formState.tokenSymbol}
                </Grid>
            </Grid>

        </Grid>

        <Grid item xs={12} md={12} sx={{textAlign: "left"}}>

            <Grid container direction="row">
                <Grid item xs={6} md={5} sx={{textAlign: "left"}}>
                    <b>Initial Supply:</b>
                </Grid>
                <Grid item xs={6} md={7} sx={{textAlign: "left"}}>
                    {props.formState.initialSupply}<br/>
                </Grid>
            </Grid>

            <Grid container direction="row">
                <Grid item xs={6} md={5} sx={{textAlign: "left"}}>
                    <b>Total Supply:</b>
                </Grid>
                <Grid item xs={6} md={7} sx={{textAlign: "left"}}>
                    {props.formState.totalSupply}<br/>
                </Grid>
            </Grid>

        </Grid>


        <Grid item xs={12} md={12} sx={{textAlign: "left",}}>
            <FormControl fullWidth sx={{m: 1}} onClick={handleClickByte} aria-describedby={idByte}>
                <TextField
                    disabled
                    label="Bytecode - click to copy"
                    multiline
                    rows="12"
                    defaultValue={byte}
                    variant="filled"
                />
            </FormControl>
            <Popover
                id={idByte}
                open={openByte}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Typography sx={{p: 1}}>Copied to Clipboard.</Typography>
            </Popover>

        </Grid>
        <Grid item xs={12} md={12} sx={{textAlign: "left",}}>
            <FormControl fullWidth sx={{m: 1}} onClick={handleClickSource} aria-describedby={idSource}>
                <TextField
                    disabled
                    label="Source - click to copy"
                    multiline
                    rows="12"
                    defaultValue={source}
                    variant="filled"
                />
            </FormControl>
            <Popover
                id={idSource}
                open={openSource}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Typography sx={{p: 1}}>Copied to Clipboard.</Typography>
            </Popover>
        </Grid>


        <Grid item xs={12} md={12} sx={{textAlign: "left",}}>


            <Grid item xs={12} md={12} sx={{textAlign: "left",}}>
                <FormControl aria-describedby={id} fullWidth sx={{m: 1}} onClick={handleClick}>
                    <TextField
                        disabled
                        label="ABI Contract Methods - click to copy"
                        multiline
                        rows="12"
                        value={abi}
                        variant="filled"
                    />
                </FormControl>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Typography sx={{p: 1}}>Copied to Clipboard.</Typography>
                </Popover>

            </Grid>

        </Grid>


        <Grid container direction="row">

            <Grid item xs={12} md={12} sx={{textAlign: "left"}}>


            </Grid>
        </Grid>


    </React.Fragment>


}

export default Deploy;