import './App.css';
import FormWrapper from "./checkout/FormWrapper";

import DocumentMeta from 'react-document-meta';
import { MetaMaskProvider } from "metamask-react";
function App() {
    const meta = {
        title: 'PRC20GEN',
        description: 'Smart Contract Generator for PulseChain',
        canonical: 'https://prc20gen.com',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'pulsechain, pls, pulsex, smart contract generator'
            }
        }
    };
  return (
      <DocumentMeta {...meta}>
    <div className="App">
      <header>

      </header>
      <body>

      <MetaMaskProvider>
<FormWrapper/>

      </MetaMaskProvider>
      </body>
    </div>
      </DocumentMeta>
  );
}

export default App;
