import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {useState} from "react";
import PulseBeat from "./PulseBeat"


export function DeployReceipt(props) {

    const [confirmations, setConfirmations] = useState(0);
    const [address, setAddress] = useState(0);



    React.useEffect( () => {
        setConfirmations(props.formState.deployReceipt.confirmationNumber)
        setAddress(props.formState.deployReceipt.contractAddress)

    },[props.formState.deployReceipt.confirmationNumber, props.formState.deployReceipt.contractAddress] )



    return <React.Fragment>

        <Typography variant="h6" gutterBottom>
            Deploy Status
        </Typography>

        <Grid item xs={12}  sx={{textAlign: "left"}}>

            <Grid container direction="row">
                <Grid item xs={12} md={12}  sx={{textAlign: "left"}}>
                    <b>Transaction Hash:</b><br/>
                    <Typography variant="subtitle2" sx={{wordWrap: 'break-word'}} gutterBottom>
                        {props.formState.deployReceipt.transactionHash}
                    </Typography>
                </Grid>


                <Grid container direction="row">
                    <Grid item xs={12} md={12}  sx={{textAlign: "left"}}>
                        <b>Contract Address:</b><br/>
                        <Typography variant="subtitle2" sx={{wordWrap: 'break-word'}} gutterBottom>
                            {address}
                        </Typography>
                    </Grid>

                </Grid>
            </Grid>

        </Grid>

        <Grid item xs={12}  sx={{textAlign: "left"}}>


            <Grid container direction="row">
                <Grid item xs={6} md={5} sx={{textAlign: "left"}}>
                    <b>Confirmations:</b>
                </Grid>
                <Grid item xs={6}  md={7} sx={{textAlign: "left"}}>
                    {confirmations}<br/>
                </Grid>
            </Grid>
            <PulseBeat/>


        </Grid>









        <Grid container direction="row">

            <Grid item xs={12} md={12} sx={{textAlign: "left"}}>


            </Grid>
        </Grid>


    </React.Fragment>


}

export default DeployReceipt;