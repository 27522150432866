import * as React from "react";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import AlertTitle from "@mui/material/AlertTitle";
import Alert from "@mui/material/Alert";


export default function ConnectionWarning(props) {
    const [open, setOpen] = React.useState(false);



    const handleClose = () => props.setOpenState(false);
    React.useEffect( () => {
        setOpen(props.openState)

    },[props.openState] )

   // const handleOpen = () => setOpen(true);
    //const handleClose = () => setOpen(false);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <div>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                onClose={handleClose}
            >
                <Box sx={style}>
                    <Alert sx={{textAlign: 'left', marginBottom: '10px'}} severity="error">
                        <AlertTitle>{props.title}</AlertTitle>
                        {props.message}
                    </Alert>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Pulsechain Network Settings
                    </Typography>
                    <Typography  sx={{mt: 1}}>
                        You will need to manually add Pulsechain Testnet and Mainnet to Metamask if you haven't already.
                    </Typography>

                    <Typography sx={{mt: 1}}>

                        1. Open Metamask and click the network selection dropdown at the top and choose "Add Network" from the bottom.
                    </Typography>
                    <Typography sx={{mt: 1}}>
                        2. Enter the following information
                    </Typography>
                    <Typography sx={{fontSize:'18px',mt: 2}}  >
                        Testnet Settings:
                    </Typography>

                    <Typography sx={{fontSize:'0.9em',mt: 0}}>
                        <b>Network Name:</b> PulseChain Testnet
                    </Typography>
                    <Typography sx={{fontSize:'0.9em',mt: 0}}>
                        <b>New RPC URL:</b> https://rpc.v2b.testnet.pulsechain.com
                    </Typography>
                    <Typography sx={{fontSize:'0.9em',mt: 0}}>
                        <b>Chain ID:</b> 941
                    </Typography>
                    <Typography sx={{fontSize:'0.9em',mt: 0}}>
                        <b>Currency Symbol:</b> tPLS
                    </Typography>
                    <Typography sx={{fontSize:'0.9em',mt: 0}}>
                        <b>Block Explorer URL:</b> https://scan.v2.testnet.pulsechain.com
                    </Typography>

                    <Typography sx={{fontSize:'18px',mt: 2}}  >
                        Mainnet Settings:
                    </Typography>

                    <Typography sx={{fontSize:'0.9em',mt: 0}}>
                        <b>Network Name:</b> PulseChain
                    </Typography>
                    <Typography sx={{fontSize:'0.9em',mt: 0}}>
                        <b>New RPC URL:</b> To be announced
                    </Typography>
                    <Typography sx={{fontSize:'0.9em',mt: 0}}>
                        <b>Chain ID:</b> 369
                    </Typography>
                    <Typography sx={{fontSize:'0.9em',mt: 0}}>
                        <b>Currency Symbol:</b> PLS
                    </Typography>
                    <Typography sx={{fontSize:'0.9em',mt: 0}}>
                        <b>Block Explorer URL:</b> To be announced
                    </Typography>

                </Box>
            </Modal>
        </div>

    );

}

