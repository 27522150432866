import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";


function getType(type) {
    if (type === 0) {
        return 'Fixed Supply';

    } else if (type === 1) {
        return 'Unlimited Mintable';

    } else if (type === 2) {
        return 'Capped Mintable';

    }

}

function getNetwork(type) {
    if (type === '0x3ac') {
        return 'Testnet';

    } else if (type === '0x171') {
        return 'Mainnet';

    } else {
        return 'Unknown';
    }

}


export function TokenReview(props) {


    return <React.Fragment>
        <Typography variant="h6" gutterBottom>
            Token Review
        </Typography>





        <Grid container direction="row" sx={{marginBottom: '14px'}}>
            <Grid item xs={12} md={8} sx={{textAlign: "left"}}>

                <Grid container direction="row">
                    <Grid item xs={6} md={6} sx={{textAlign: "left"}}>
                        <b>Token Name:</b>
                    </Grid>
                    <Grid item xs={6} md={6} sx={{textAlign: "left"}}>
                        {props.formState.tokenName}
                    </Grid>
                </Grid>
                <Grid container direction="row">
                    <Grid item xs={6} md={6} sx={{textAlign: "left"}}>
                        <b>Token Symbol:</b>
                    </Grid>
                    <Grid item xs={6} md={6} sx={{textAlign: "left"}}>
                        {props.formState.tokenSymbol}<br/>
                    </Grid>
                </Grid>
                <Grid container direction="row">
                    <Grid item xs={6} md={6} sx={{textAlign: "left"}}>
                        <b>Network:</b>
                    </Grid>
                    <Grid item xs={6} md={6} sx={{textAlign: "left"}}>
                        {getNetwork(props.formState.tokenNetwork)}
                    </Grid>
                </Grid>


            </Grid>

        </Grid>
        <hr/>
        <Grid container direction="row" sx={{marginBottom: '14px'}}>

            <Grid item xs={12} md={8} sx={{textAlign: "left"}}>

                    <Grid container direction="row">
                        <Grid item xs={6} md={6} sx={{textAlign: "left"}}>
                    <b>Supply Type:</b>
                        </Grid>
                        <Grid item xs={6} md={6} sx={{textAlign: "left"}}>
                    {getType(props.formState.tokenType)}<br/>
                        </Grid>
                    </Grid>
                <Grid container direction="row">
                    <Grid item xs={6} md={6} sx={{textAlign: "left"}}>
                        <b>Initial Supply:</b>
                    </Grid>
                    <Grid item xs={6} md={6} sx={{textAlign: "left"}}>
                        {props.formState.initialSupply}<br/>
                    </Grid>
                </Grid>

                <Grid container direction="row">
                    <Grid item xs={6} md={6} sx={{textAlign: "left"}}>
                        <b>Total Supply:</b>
                    </Grid>
                    <Grid item xs={6} md={6} sx={{textAlign: "left"}}>
                        {props.formState.totalSupply}<br/>
                    </Grid>
                </Grid>


                <Grid container direction="row">
                    <Grid item xs={6} md={6} sx={{textAlign: "left"}}>
                        <b>Decimals:</b>
                    </Grid>
                    <Grid item xs={6} md={6} sx={{textAlign: "left"}}>
                        {props.formState.tokenDecimals}<br/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <hr/>

        <Grid container direction="row" sx={{marginBottom: '14px'}}>
            <Grid item xs={6} md={6} sx={{textAlign: "left"}}>
                <Grid container direction="row">
                    <Grid item xs={9} md={6} sx={{textAlign: "left"}}>
                        <b>Burnable:</b>
                    </Grid>
                    <Grid item xs={3} md={6} sx={{textAlign: "left"}}>
                        {props.formState.templateOptions.burnable ? 'Yes' : 'No'}<br/>
                    </Grid>
                </Grid>
                <Grid container direction="row">
                    <Grid item xs={9} md={6} sx={{textAlign: "left"}}>
                        <b>Ownable:</b>
                    </Grid>
                    <Grid item xs={3} md={6} sx={{textAlign: "left"}}>
                        {props.formState.templateOptions.ownable ? 'Yes' : 'No'}<br/>
                    </Grid>
                </Grid>

                <Grid container direction="row">
                    <Grid item xs={9} md={6} sx={{textAlign: "left"}}>
                        <b>Roles:</b>
                    </Grid>
                    <Grid item xs={3} md={6} sx={{textAlign: "left"}}>
                        {props.formState.templateOptions.roles ? 'Yes' : 'No'}<br/>
                    </Grid>
                </Grid>

                <Grid container direction="row">
                    <Grid item xs={9} md={6} sx={{textAlign: "left"}}>
                        <b>Votes:</b>
                    </Grid>
                    <Grid item xs={3} md={6} sx={{textAlign: "left"}}>
                        {props.formState.templateOptions.votes ? 'Yes' : 'No'}<br/>
                    </Grid>
                </Grid>
                <Grid container direction="row">
                    <Grid item xs={9} md={6} sx={{textAlign: "left"}}>
                        <b>Pausable:</b>
                    </Grid>
                    <Grid item xs={3} md={6} sx={{textAlign: "left"}}>
                        {props.formState.templateOptions.pausable ? 'Yes' : 'No'}<br/>
                    </Grid>
                </Grid>


            </Grid>
            <Grid item xs={6} md={6} sx={{textAlign: "left"}}>

                <Grid container direction="row">
                    <Grid item xs={9} md={6} sx={{textAlign: "left"}}>
                        <b>Permit:</b>
                    </Grid>
                    <Grid item xs={3} md={6} sx={{textAlign: "left"}}>
                        {props.formState.templateOptions.permit ? 'Yes' : 'No'}
                    </Grid>
                </Grid>

                <Grid container direction="row">
                    <Grid item xs={9} md={6} sx={{textAlign: "left"}}>
                        <b>Snapshots:</b>
                    </Grid>
                    <Grid item xs={3} md={6} sx={{textAlign: "left"}}>
                        {props.formState.templateOptions.snapshots ? 'Yes' : 'No'}
                    </Grid>
                </Grid>


                <Grid container direction="row">
                    <Grid item xs={9} md={6} sx={{textAlign: "left"}}>
                        <b>Flashminting:</b>
                    </Grid>
                    <Grid item xs={3} md={6} sx={{textAlign: "left"}}>
                        {props.formState.templateOptions.flashMinting ? 'Yes' : 'No'}
                    </Grid>
                </Grid>


                <Grid container direction="row">
                    <Grid item xs={9} md={6} sx={{textAlign: "left"}}>
                        <b>Capped:</b>
                    </Grid>
                    <Grid item xs={3} md={6} sx={{textAlign: "left"}}>
                        {props.formState.templateOptions.capped ? 'Yes' : 'No'}
                    </Grid>
                </Grid>

            </Grid>
        </Grid>
    </React.Fragment>


}

export default TokenReview;